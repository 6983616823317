body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* Fixes an iOS issue that the color is not refreshed when a button becomes active */
.MuiButton-root {
  transition: color .01s!important
}

.maintenance-page {
  text-align: center;
  font: 20px Helvetica, sans-serif;
  color: #333;
  padding: 5em;
}

.maintenance-page h1 { font-size: 50px; color:#0587B8 }
